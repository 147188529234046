/* eslint-disable no-param-reassign */
import { gql } from 'graphql-request'
import { infoClientWithChain } from 'utils/graphql'
import { Block, PoolData } from 'state/info/types'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getLpFeesAndApr, getStableLpFeesAndApr } from 'utils/getLpFeesAndApr'
import { getPercentChange } from 'pages/Info/utils/infoDataHelpers'

import { fetchTopPoolAddresses } from './topPools'

interface PoolFields {
  id: string
  reserve0: string
  reserve1: string
  reserve2?: string
  reserveUSD: string
  volumeUSD: string
  token0Price: string
  token1Price: string
  token2Price?: string
  trackedReservePLS: string
  timestamp: number
  token0?: {
    id: string
    symbol: string
    name: string
  }
  token1?: {
    id: string
    symbol: string
    name: string
  }
  token2?: {
    id: string
    symbol: string
    name: string
  }
}

interface FormattedPoolFields
  extends Omit<
    PoolFields,
    'volumeUSD' | 'reserveUSD' | 'reserve0' | 'reserve1' | 'reserve2' | 'token0Price' | 'token1Price' | 'token2Price' | 'trackedReservePLS'
  > {
  volumeUSD: number
  reserveUSD: number
  reserve0: number
  reserve1: number
  reserve2: number
  token0Price: number
  token1Price: number
  token2Price: number
  trackedReservePLS: number
}

interface PoolQueryResponse {
  data: PoolFields[]
}

export const sortByTrackedReservePLS = (a: PoolData, b: PoolData) =>
  (b.trackedReservePLS as number) - (a.trackedReservePLS as number)

export const sortByLiquidityUSD = (a: PoolData, b: PoolData) =>
  (b.liquidityUSD as number) - (a.liquidityUSD as number)

/**
 * Data for displaying pool tables (on multiple pages, used throughout the site)
 * Note: Don't try to refactor it to use variables, server throws error if blocks passed as undefined variable
 * only works if its hard-coded into query string
 */
const POOL_AT_BLOCK = (block: number | null, pools: string[], protocol: string) => {
  if (pools.length === 0) {
    return null
  }
  const blockString = block ? `block: {number: ${block}}` : ``
  const addressesString = `["${pools.join('","')}"]`
  return `pairs(
    where: { id_in: ${addressesString} }
    ${blockString}
  ) {
    id
    trackedReservePLS
    reserve0
    reserve1
    reserveUSD
    volumeUSD
    token0Price
    token1Price
    timestamp
    token0 {
      id
      symbol
      name
    }
    token1 {
      id
      symbol
      name
    }
    ${protocol === 'stable' ? 
    `token2 {
      id
      symbol
      name
    }
    reserve2
    token2Price` : ''}
  }`
}

const fetchPoolData = async (
  chainId: number,
  protocol: string,
  block24h: number,
  block48h: number,
  block7d: number,
  block14d: number,
  poolAddresses: string[],
) => {
  try {
    const queries = [
      POOL_AT_BLOCK(null, poolAddresses, protocol),
      POOL_AT_BLOCK(block24h, poolAddresses, protocol),
      POOL_AT_BLOCK(block48h, poolAddresses, protocol),
      POOL_AT_BLOCK(block7d, poolAddresses, protocol),
      POOL_AT_BLOCK(block14d, poolAddresses, protocol),
    ].filter(query => query !== null);

    if (queries.length === 0) {
      return { data: [], error: false };
    }

    const promises = queries.map((query) => {
      const gqlQuery = gql`
        query pools {
          data: ${query}
        }
      `
      return infoClientWithChain(chainId, protocol)?.request<PoolQueryResponse>(gqlQuery)
    })

    const results = await Promise.all(promises)

    return { data: results.map(result => result?.data), error: false }
  } catch (error) {
    console.error('Failed to fetch pool data', error)
    return { error: true }
  }
}

// Transforms pools into "0xADDRESS: { ...PoolFields }" format and cast strings to numbers
export const parsePoolData = (pairs?: PoolFields[]) => {
  if (!pairs) {
    return {}
  }
  return pairs.reduce((accum: { [address: string]: FormattedPoolFields }, poolData) => {
    const { volumeUSD, reserveUSD, reserve0, reserve1, reserve2, token0Price, token1Price, token2Price, trackedReservePLS } = poolData
    accum[poolData.id] = {
      ...poolData,
      volumeUSD: parseFloat(volumeUSD),
      reserveUSD: parseFloat(reserveUSD),
      reserve0: parseFloat(reserve0),
      reserve1: parseFloat(reserve1),
      reserve2: parseFloat(reserve2 ?? ''),
      token0Price: parseFloat(token0Price),
      token1Price: parseFloat(token1Price),
      token2Price: parseFloat(token2Price ?? ''),
      trackedReservePLS: parseFloat(trackedReservePLS ?? '')
    }
    return accum
  }, {})
}

export const fetchAllPoolDataWithAddress = async (
  chainId: number,
  protocol: string,
  blocks: Block[],
  poolAddresses: string[],
  sortBy?: (a: PoolData, b: PoolData) => number
) => {
  const [block24h, block48h, block7d, block14d] = blocks ?? []

  // Helper function to chunk the pool addresses
  const chunkArray = (arr: string[], chunkSize: number): string[][] => {
    const result: string[][] = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize))
    }
    return result
  }

  // Split pool addresses into chunks
  const addressChunks = chunkArray(poolAddresses, 5)

  // Fetch data for each chunk in parallel
  const fetchDataPromises = addressChunks.map((chunk) =>
    fetchPoolData(chainId, protocol, block24h?.number, block48h?.number, block7d?.number, block14d?.number, chunk)
  )

  // Wait for all chunks to be fetched
  const dataResults = await Promise.all(fetchDataPromises)

  // Merge all fetched data
  const processedData: { [address: string]: { data: PoolData } } = dataResults.reduce((accum, data) => {
    const currentData = parsePoolData(data?.data?.[0])
    const data24h = parsePoolData(data?.data?.[1])
    const data48h = parsePoolData(data?.data?.[2])
    const data7d = parsePoolData(data?.data?.[3])
    const data14d = parsePoolData(data?.data?.[4])

    Object.keys(currentData).forEach((address) => {
      const current = currentData[address]
      const oneDay = data24h[address]
      const twoDays = data48h[address]
      const week = data7d[address]
      const twoWeeks = data14d[address]

      const [volumeUSD, volumeUSDChange] = getChangeForPeriod(current?.volumeUSD, oneDay?.volumeUSD, twoDays?.volumeUSD)
      const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
        current?.volumeUSD,
        week?.volumeUSD,
        twoWeeks?.volumeUSD,
      )

      const liquidityUSD = current ? current.reserveUSD : 0
      const liquidityUSDChange = getPercentChange(current?.reserveUSD, oneDay?.reserveUSD)
      const liquidityToken0 = current ? current.reserve0 : 0
      const liquidityToken1 = current ? current.reserve1 : 0
      const liquidityToken2 = current ? current.reserve2 : 0
      const timestamp = current.timestamp ?? 0

      const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = protocol === 'v1' ? {
        totalFees24h: 0,
        totalFees7d: 0,
        lpFees24h: 0,
        lpFees7d: 0,
        lpApr7d: 0
      } : protocol === 'stable' ? getStableLpFeesAndApr(
        volumeUSD,
        volumeUSDWeek,
        liquidityUSD,
      ) : getLpFeesAndApr(
        volumeUSD,
        volumeUSDWeek,
        liquidityUSD,
      )

      if (current) {
        accum[address] = {
          data: {
            address,
            token0: {
              address: current?.token0?.id ?? '',
              name: current?.token0?.name ?? '',
              symbol: current?.token0?.symbol ?? '',
            },
            token1: {
              address: current?.token1?.id ?? '',
              name: current?.token1?.name ?? '',
              symbol: current?.token1?.symbol ?? '',
            },
            token2: {
              address: current?.token2?.id ?? '',
              name: current?.token2?.name ?? '',
              symbol: current?.token2?.symbol ?? '',
            },
            timestamp,
            token0Price: current.token0Price,
            token1Price: current.token1Price,
            token2Price: current?.token2Price,
            volumeUSD,
            volumeUSDChange,
            volumeUSDWeek,
            volumeUSDChangeWeek,
            totalFees24h,
            totalFees7d,
            lpFees24h,
            lpFees7d,
            lpApr7d,
            liquidityUSD,
            liquidityUSDChange,
            liquidityToken0,
            liquidityToken1,
            liquidityToken2,
            trackedReservePLS: current?.trackedReservePLS,
          },
        }
      }
    })

    return accum
  }, {})

  // If a sort function is provided, use it to sort the data
  if (sortBy) {
    const sortedEntries = Object.entries(processedData)
      .sort(([, a], [, b]) => sortBy(a.data, b.data))

    return Object.fromEntries(sortedEntries) as { [address: string]: { data: PoolData } }
  }

  // If no sort function is provided, return the unsorted data
  return processedData
}

export const fetchAllPoolData = async (
  chainId: number,
  protocol: string,
  blocks: Block[],
  sortBy?: (a: PoolData, b: PoolData) => number
) => {
  const poolAddresses = await fetchTopPoolAddresses(chainId, protocol)
  return fetchAllPoolDataWithAddress(chainId, protocol, blocks, poolAddresses, sortBy)
}
