export const MINIMUM_SEARCH_CHARACTERS = 3

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0029
export const LP_HOLDERS_FEE = 0.0022
export const TREASURY_FEE = 0.0
export const BUYBACK_FEE = 0.0007

export const PCS_V2_START = 1619136000 // April 23, 2021, 12:00:00 AM
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume
export const TOKEN_BLACKLIST = [""]
