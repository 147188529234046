import { TOKEN_BLACKLIST } from 'config/constants/info'
import { gql } from 'graphql-request'
import { infoClientWithChain } from 'utils/graphql'

/**
 * Data for showing Pools table on the Token page
 */
interface PoolsForTokenResponse {
  asToken0: {
    id: string
  }[]
  asToken1: {
    id: string
  }[]
  asToken2?: {
    id: string
  }[]
}

const fetchPoolsForToken = async (
  chainId: number,
  protocol: string,
  address: string,
): Promise<{
  error: boolean
  addresses?: string[]
}> => {
  try {
    const query = gql`
      query poolsForToken($address: Bytes!, $blacklist: [String!]) {
        asToken0: pairs(
          first: 200
          orderBy: trackedReservePLS
          orderDirection: desc
          where: { totalTransactions_gt: 1, token0: $address, token1_not_in: $blacklist }
        ) {
          id
        }
        asToken1: pairs(
          first: 200
          orderBy: trackedReservePLS
          orderDirection: desc
          where: { totalTransactions_gt: 1, token1: $address, token0_not_in: $blacklist }
        ) {
          id
        }
        ${protocol === 'stable' ? `
        asToken2: pairs(
          first: 200
          orderBy: trackedReservePLS
          orderDirection: desc
          where: { totalTransactions_gt: 1, token2: $address }
        ) {
          id
        }
        ` : ''}
      }
    `
    const data = await infoClientWithChain(chainId, protocol).request<PoolsForTokenResponse>(query, {
      address,
      blacklist: TOKEN_BLACKLIST,
    })

    const addresses = [
      ...data.asToken0,
      ...data.asToken1,
      ...(data.asToken2 || []),
    ].map((p) => p.id)

    return {
      error: false,
      addresses
    }
  } catch (error) {
    console.error(`Failed to fetch pools for token ${address}`, error)
    return {
      error: true,
    }
  }
}

export default fetchPoolsForToken
