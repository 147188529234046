/* eslint-disable no-param-reassign */
import { gql } from 'graphql-request'
import { Block, TokenData } from 'state/info/types'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getAmountChange, getPercentChange } from 'pages/Info/utils/infoDataHelpers'
import { infoClientWithChain } from 'utils/graphql'
import { fetchTokenAddresses } from './topTokens'

interface TokenFields {
  id: string
  symbol: string
  name: string
  derivedPLS: string // Price in PLS per token
  derivedUSD: string // Price in USD per token
  tradeVolumeUSD: string
  totalTransactions: string
  totalLiquidity: string
}

interface FormattedTokenFields
  extends Omit<TokenFields, 'derivedPLS' | 'derivedUSD' | 'tradeVolumeUSD' | 'totalTransactions' | 'totalLiquidity'> {
  derivedPLS: number
  derivedUSD: number
  tradeVolumeUSD: number
  totalTransactions: number
  totalLiquidity: number
}

interface TokenQueryResponse {
  data: TokenFields[]
}

export const sortByTradeVolumeUSD = (a: TokenData, b: TokenData) =>
  (b.volumeUSD as number) - (a.volumeUSD as number)

/**
 * Main token data to display on Token page
 */
const TOKEN_AT_BLOCK = (block: number | undefined, tokens: string[]) => {
  if (tokens.length === 0) {
    return null
  }
  const addressesString = `["${tokens.join('","')}"]`
  const blockString = block ? `block: {number: ${block}}` : ``
  return `tokens(
      where: {id_in: ${addressesString}}
      ${blockString}
    ) {
      id
      tradeVolumeUSD
      symbol
      name
      derivedPLS
      derivedUSD
      totalTransactions
      totalLiquidity
    }
  `
}

const fetchTokenData = async (
  chainId: number,
  protocol: string,
  block24h: number,
  block48h: number,
  block7d: number,
  block14d: number,
  tokenAddresses: string[],
) => {
  try {
    const queries = [
      TOKEN_AT_BLOCK(undefined, tokenAddresses),
      TOKEN_AT_BLOCK(block24h, tokenAddresses),
      TOKEN_AT_BLOCK(block48h, tokenAddresses),
      TOKEN_AT_BLOCK(block7d, tokenAddresses),
      TOKEN_AT_BLOCK(block14d, tokenAddresses),
    ].filter(query => query !== null);

    if (queries.length === 0) {
      return { data: [], error: false };
    }

    const promises = queries.map((query) => {
      const gqlQuery = gql`
        query tokens {
          data: ${query}
        }
      `
      return infoClientWithChain(chainId, protocol).request<TokenQueryResponse>(gqlQuery)
    })

    const results = await Promise.all(promises)

    return { data: results.map(result => result.data), error: false }
  } catch (error) {
    console.error('Failed to fetch token data', error)
    return { error: true }
  }
}

// Transforms tokens into "0xADDRESS: { ...TokenFields }" format and cast strings to numbers
const parseTokenData = (tokens?: TokenFields[]) => {
  if (!tokens) {
    return {}
  }
  return tokens.reduce((accum: { [address: string]: FormattedTokenFields }, tokenData) => {
    const { derivedPLS, derivedUSD, tradeVolumeUSD, totalTransactions, totalLiquidity } = tokenData
    accum[tokenData.id] = {
      ...tokenData,
      derivedPLS: parseFloat(derivedPLS),
      derivedUSD: parseFloat(derivedUSD),
      tradeVolumeUSD: parseFloat(tradeVolumeUSD),
      totalTransactions: parseFloat(totalTransactions),
      totalLiquidity: parseFloat(totalLiquidity),
    }
    return accum
  }, {})
}

export const fetchAllTokenDataByAddresses = async (
  chainId: number,
  protocol: string,
  blocks: Block[],
  tokenAddresses: string[],
  sortBy?: (a: TokenData, b: TokenData) => number
) => {
  const [block24h, block48h, block7d, block14d] = blocks ?? []

  // Helper function to chunk the token addresses
  const chunkArray = (arr: string[], chunkSize: number): string[][] => {
    const result: string[][] = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize))
    }
    return result
  }

  // Split token addresses into chunks
  const addressChunks = chunkArray(tokenAddresses, 5)

  // Fetch data for each chunk in parallel
  const fetchDataPromises = addressChunks.map((chunk) =>
    fetchTokenData(chainId, protocol, block24h?.number, block48h?.number, block7d?.number, block14d?.number, chunk)
  )

  // Wait for all chunks to be fetched
  const dataResults = await Promise.all(fetchDataPromises)

  // Merge all fetched data
  const processedData = dataResults.reduce(
    (accum, data) => {
      const currentData = parseTokenData(data?.data?.[0])
      const data24h = parseTokenData(data?.data?.[1])
      const data48h = parseTokenData(data?.data?.[2])
      const data7d = parseTokenData(data?.data?.[3])
      const data14d = parseTokenData(data?.data?.[4])

      Object.keys(currentData).forEach((address) => {
        const current = currentData[address]
        const oneDay = data24h[address]
        const twoDays = data48h[address]
        const week = data7d[address]
        const twoWeeks = data14d[address]

        const [volumeUSD, volumeUSDChange] = getChangeForPeriod(
          current?.tradeVolumeUSD,
          oneDay?.tradeVolumeUSD,
          twoDays?.tradeVolumeUSD,
        )
        const [volumeUSDWeek] = getChangeForPeriod(current?.tradeVolumeUSD, week?.tradeVolumeUSD, twoWeeks?.tradeVolumeUSD)
        const liquidityUSD = current ? current.totalLiquidity * current.derivedUSD : 0
        const liquidityUSDOneDayAgo = oneDay ? oneDay.totalLiquidity * oneDay.derivedUSD : 0
        const liquidityUSDChange = getPercentChange(liquidityUSD, liquidityUSDOneDayAgo)
        const liquidityToken = current ? current.totalLiquidity : 0
        // Prices of tokens for now, 24h ago and 7d ago
        const priceUSD = current ? current.derivedUSD : 0
        const priceUSDOneDay = oneDay ? oneDay.derivedUSD : 0
        const priceUSDWeek = week ? week.derivedUSD : 0
        const priceUSDChange = getPercentChange(priceUSD, priceUSDOneDay)
        const priceUSDChangeWeek = getPercentChange(priceUSD, priceUSDWeek)
        const txCount = getAmountChange(current?.totalTransactions, oneDay?.totalTransactions)

        accum[address] = {
          data: {
            exists: !!current,
            address,
            name: current ? current.name : '',
            symbol: current ? current.symbol : '',
            volumeUSD,
            volumeUSDChange,
            volumeUSDWeek,
            txCount,
            liquidityUSD,
            liquidityUSDChange,
            liquidityToken,
            priceUSD,
            priceUSDChange,
            priceUSDChangeWeek,
          },
        }
      })

      return accum
    }, {} as { [address: string]: { data: TokenData } },
  )

  // If a sort function is provided, use it to sort the data
  if (sortBy) {
    const sortedEntries = Object.entries(processedData)
      .sort(([, a], [, b]) => sortBy(a.data, b.data))

    return Object.fromEntries(sortedEntries)
  }

  // If no sort function is provided, return the unsorted data
  return processedData
}

export const fetchAllTokenData = async (
  chainId: number,
  protocol: string,
  blocks: Block[],
  sortBy?: (a: TokenData, b: TokenData) => number
) => {
  const tokenAddresses = await fetchTokenAddresses(chainId, protocol)
  return fetchAllTokenDataByAddresses(chainId, protocol, blocks, tokenAddresses, sortBy)
}
