import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useAllPoolDataQuery } from 'state/info/hooks'
import { PoolData } from 'state/info/types'

interface Pool {
  data: PoolData
}

export const usePoolsData = (protocol: string) => {

  // get all the pool datas that exist
  const allPoolData = useAllPoolDataQuery(protocol)

  // get all the pool datas that exist
  const poolsData = useMemo(() => {
    return Object.values(allPoolData)
      .map((pool) => {
        const typedPool = pool as Pool
        return {
          ...typedPool.data,
        }
      })
      .filter((pool) => pool.token1.name !== 'unknown' && pool.token0.name !== 'unknown')
  }, [allPoolData])
  return { poolsData }
}

export const useNonSpamPoolsData = (protocol: string) => {
  const { poolsData: rawPoolsData, ...others } = usePoolsData(protocol)

  const poolsData = useMemo(() => {
    if (protocol === 'stable') {
      // no need to filter the data for stableswap
      return rawPoolsData
    }

    return rawPoolsData.reduce((acc, data) => {
      // Ensure that the length of the array does not exceed 10
      if (acc.length >= 10) {
        acc.push(data)
        return acc
      }

      // Determine if the pool data is spam (created within the last 4 days)
      const isSpam = dayjs().diff(dayjs.unix(data.timestamp), 'day') < 4

      // If it is spam, do not add it to the result
      if (isSpam) return acc

      // Add valid pools to the result
      acc.push(data)
      return acc
    }, [] as typeof rawPoolsData)
  }, [rawPoolsData, protocol])

  return {
    poolsData,
    ...others,
  }
}
